import './Homepage.scss';

import { CardStep, cardSteps } from 'stores/CardStepStore';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import AboutTMCard from './InfoLinks/AboutTMCard';
import CookiePolicyCard from './InfoLinks/CookiePolicyCard';
import { DefaultRoutes } from 'Routes';
import EmailVerificationCard from './Cards/EmailVerificationCard';
import ForgotPasswordCard from './Cards/ForgotPasswordCard';
import MFACard from './Cards/MFACard';
import PrivacyNoteCard from './Cards/PrivacyNoteCard';
import PrivacyNoticeCard from './InfoLinks/PrivacyNoticeCard';
import SignInCard from './Cards/SignInCard';
import SignUpCard from './Cards/SignUpCard';
import { TabBar } from 'components/TabBar/TabBar';
import TermsAndConditionsCard from './InfoLinks/TermsAndConditionsCard';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useStores } from 'index';

export const Homepage = observer(() => {
  const [containerHeight, setContainerHeight] = useState(0);
  const css = useStores().cardStepStore;
  const location = useLocation();

  const containerRef = useRef<HTMLDivElement>(null);
  const containerWidth = useMemo(() => (containerRef.current ? containerRef.current?.getBoundingClientRect().width : '100%'), []);

  // refMapper cannot be wrapped with useMemo, since useRef hooks are used inside of it
  // refMapper will be created on every render, but this will happen only once per user flow, so it will not influence performance, hence eslint-disable
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refMapper: { [key in CardStep]: MutableRefObject<HTMLDivElement | null> } = {
    signIn: useRef<HTMLDivElement>(null),
    MFA: useRef<HTMLDivElement>(null),
    forgotPassword: useRef<HTMLDivElement>(null),
    signUp: useRef<HTMLDivElement>(null),
    privacyNote: useRef<HTMLDivElement>(null),
    emailVerification: useRef<HTMLDivElement>(null),
    aboutTM: useRef<HTMLDivElement>(null),
    cookiePolicy: useRef<HTMLDivElement>(null),
    privacyNotice: useRef<HTMLDivElement>(null),
    termsAndConditions: useRef<HTMLDivElement>(null),
  } as const;

  useEffect(() => setContainerHeight(refMapper[css.cardStep].current?.getBoundingClientRect().height ?? 0), [css.cardStep, refMapper]);

  useEffect(() => {
    if (location.pathname === DefaultRoutes.SignUp) css.setCardStep('signUp');
    if (location.pathname === DefaultRoutes.SignIn) css.setCardStep('signIn');

    if (location.pathname === DefaultRoutes.About) css.setCardStep('aboutTM');
    if (location.pathname === DefaultRoutes.CookiePolicy) css.setCardStep('cookiePolicy');
    if (location.pathname === DefaultRoutes.PrivacyNotice) css.setCardStep('privacyNotice');
    if (location.pathname === DefaultRoutes.TermsAndConditions) css.setCardStep('termsAndConditions');
  }, [location.pathname, css]);

  const componentMapper: { [key in CardStep]: React.ReactElement } = {
    signIn: <SignInCard />,
    MFA: <MFACard />,
    forgotPassword: <ForgotPasswordCard />,
    signUp: <SignUpCard />,
    privacyNote: <PrivacyNoteCard />,
    emailVerification: <EmailVerificationCard />,
    aboutTM: <AboutTMCard />,
    cookiePolicy: <CookiePolicyCard />,
    privacyNotice: <PrivacyNoticeCard />,
    termsAndConditions: <TermsAndConditionsCard />,
  } as const;

  // Browsers optimize heavily for performance, and the input fields sometimes load in the wrong position, so box is rerendered after height transition finishes.
  const [hasRerendered, setHasRerendered] = useState(false);

  useEffect(() => {
    setTimeout(() => setHasRerendered(true), 300);
  }, []);

  return (
    <div ref={containerRef} className='home-container'>
      {css.cardStep !== 'emailVerification' && <TabBar />}
      <div
        className='home-cards-container'
        style={{
          maxHeight: containerHeight,
          maxWidth: containerWidth,
        }}
        key={'home-cards-container-key-' + hasRerendered}
      >
        {cardSteps.map((cardStepMap) => (
          <div
            className='home-card'
            ref={refMapper[cardStepMap]}
            key={cardStepMap}
            style={{
              visibility: css.cardStep === cardStepMap ? 'visible' : 'hidden', // needed to disable tabbing order of next cards
              opacity: css.cardStep === cardStepMap ? 1 : 0, // for transition
              transform: `translateX(${-100 * cardSteps.indexOf(css.cardStep)}%)`,
            }}
          >
            {componentMapper[cardStepMap]}
          </div>
        ))}
      </div>
    </div>
  );
});

export default Homepage;
