import './App.scss';

import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { SportBg, sportBgs } from 'stores/AuthStore';
import { ThemeProviderProps, ThemeProvider as _ThemeProvider } from '@trackman/web-shared-components';
import { useHistory, useLocation } from 'react-router-dom';

import { DefaultRoutes } from 'Routes';
import { Notifications } from 'components/Notifications';
import logoPicture from './images/MainMenu/TM-logo.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

const ThemeProvider = _ThemeProvider as unknown as FC<ThemeProviderProps & { children?: React.ReactNode }>;

interface IProps {
  children?: React.ReactNode;
}

export const App: FC<IProps> = observer((props) => {
  const css = useStores().cardStepStore;
  const as = useStores().authStore;
  const { t } = useTranslation();
  const { search, pathname } = useLocation();
  const history = useHistory();

  // setting height dynamically via JS is needed to solve the Webview screen bug in iOS, it cannot be solved with pure CSS at the moment
  const [containerHeight, setContainerHeight] = useState<number | string>('100dvh');

  useEffect(() => {
    document.title = t('page.title');
  }, [t]);

  const appContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (appContainerRef.current) setContainerHeight(appContainerRef.current.getBoundingClientRect().height);
    const handleResize = () => {
      if (appContainerRef.current) setContainerHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    css.appContainerRef = appContainerRef;
  }, [appContainerRef, css]);

  const sportBg: SportBg = useMemo(() => {
    const query = new URLSearchParams(search);
    const sportBgFromCookie = document.cookie.match('(^|;)\\s*sportBg\\s*=\\s*([^;]+)')?.pop(); // the cookie lives for 60 seconds, so we don't need to delete it (clearing cookies will break ability to login)
    if (sportBgFromCookie) {
      query.set('sportBg', sportBgFromCookie);
      history.replace({ pathname, search: query.toString() });
    }
    const sportBgFromQuery = query.get('sportBg')?.toLowerCase();
    const sportBg = sportBgFromQuery ?? sportBgFromCookie;
    if (sportBg && sportBgs.includes(sportBg)) return sportBg;
    if (search.includes('trackmanbaseball.com') || search.includes('tmbaseball')) return 'baseball';
    if (search.includes('trackmanfootballus.com') || search.includes('tmfootball')) return 'footballus';
    if (search.includes('trackmansoccer.com') || search.includes('tmsoccer')) return 'soccer';
    return 'baseball';
  }, [search, history, pathname]);

  useEffect(() => {
    as.sportBg = sportBg;
  }, [sportBg, as]);

  return (
    <ThemeProvider>
      <>
        <Notifications />
        <div
          className='app-scroll-container'
          ref={appContainerRef}
          style={{
            background: `url(/bg/bg-small-${sportBg}.jpeg) center center / cover, url(/bg/bg-${sportBg}.jpeg) center center / cover rgba(0, 0, 0, ${
              sportBg === 'footballus' ? '0.6' : '0.3'
            })`,
            backgroundBlendMode: 'darken',
          }}
        >
          <div className='app-container' style={{ height: containerHeight }}>
            <img src={logoPicture} alt='trackman logo' className='app-tm-logo' />
            <div className='container'>{props.children}</div>
            <div className='app-tm-footer'>
              {pathname !== DefaultRoutes.ResetPassword &&
                pathname !== DefaultRoutes.Logout &&
                pathname !== DefaultRoutes.Error &&
                css.cardStep !== 'emailVerification' && (
                  <>
                    <div className='app-tm-footer-link' onClick={() => history.replace(DefaultRoutes.About + search)}>
                      <span className='lg-view'>{t('index.aboutTrackman')}</span>
                      <span className='sm-view'>{t('index.aboutTrackman.short')}</span>
                    </div>
                    <div className='app-tm-footer-link' onClick={() => history.replace(DefaultRoutes.CookiePolicy + search)}>
                      <span className='lg-view'>{t('index.cookiePolicy')}</span>
                      <span className='sm-view'>{t('index.cookiePolicy.short')}</span>
                    </div>
                    <div className='app-tm-footer-link' onClick={() => history.replace(DefaultRoutes.PrivacyNotice + search)}>
                      <span className='lg-view'>{t('index.privacyNotice')}</span>
                      <span className='sm-view'>{t('index.privacyNotice.short')}</span>
                    </div>
                    <div className='app-tm-footer-link' onClick={() => history.replace(DefaultRoutes.TermsAndConditions + search)}>
                      <span className='lg-view'>{t('index.termsAndConditions')}</span>
                      <span className='sm-view'>{t('index.termsAndConditions.short')}</span>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </>
    </ThemeProvider>
  );
});

export default App;
