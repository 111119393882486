import { ForgotPasswordModel, LoginModel, LoginWith2faModel, ResetPasswordModel, SignUpErrorCodes, SignUpModel } from 'stores/AuthStore';

import { AxiosInstance } from 'axios';
import { Result } from './Result';

type SignInReturn = {
  redirect_url: string;
  is_local_url: boolean;
};

const mfaRequiredResponse = 'MFA required';

export class AuthService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  addTokenHeader = () => {
    const cookies = {};
    document.cookie.split(';').forEach((rawPair) => {
      const pair = rawPair.trim();
      const index = pair.indexOf('=');
      const key = pair.slice(0, index);
      const value = pair.slice(index + 1);
      cookies[key] = value;
    });
    return { headers: { 'X-XSRF-TOKEN': cookies['XSRF-TOKEN'] } };
  };
  assingnUrlAfterSuccesfulLogin = (response) => {
    window.location.assign(
      (response.data.data.is_local_url ? this.axiosInstance.defaults.baseURL ?? '' : '') + response.data.data.redirect_url
    );
  };

  signIn = async (loginModel: LoginModel, returnUrl: string) => {
    const response = await this.axiosInstance.post<Result<SignInReturn> | typeof mfaRequiredResponse>(`Auth/Login`, loginModel, {
      ...this.addTokenHeader(),
      params: {
        returnUrl: returnUrl,
      },
    });
    if (!response && !window.navigator.onLine) throw Error('You are offline. Please check your internet connection.');
    if (response && response.status === 200 && response.data && response.data !== mfaRequiredResponse && response.data.data) {
      this.assingnUrlAfterSuccesfulLogin(response);
      return;
    }
    if (response && response.status === 401 && response.data === mfaRequiredResponse) {
      return mfaRequiredResponse;
    }
    if (response && response.status === 401) throw Error('Incorrect email or password.');
    throw Error('There was an internal error while sign in.');
  };

  submitMFA = async (loginWith2faModel: LoginWith2faModel, returnUrl: string) => {
    const response = await this.axiosInstance.post(`Auth/LoginWith2fa`, loginWith2faModel, {
      ...this.addTokenHeader(),
      params: {
        returnUrl: returnUrl,
      },
    });
    if (!response && !window.navigator.onLine) throw Error('You are offline. Please check your internet connection.');
    if (response && response.status === 200 && response.data && response.data !== mfaRequiredResponse && response.data.data) {
      this.assingnUrlAfterSuccesfulLogin(response);
      return;
    }
    if (response && response.status === 401) throw Error('Incorrect MFA code.');
    throw Error('There was an internal error while sign in.');
  };

  signUp = async (signUpModel: SignUpModel, returnUrl: string): Promise<SignUpErrorCodes | 'success'> => {
    const response = await this.axiosInstance.post(`Auth/Register`, signUpModel, {
      ...this.addTokenHeader(),
      params: {
        returnUrl: returnUrl,
      },
    });
    if (!response && !window.navigator.onLine) throw Error('You are offline. Please check your internet connection.');
    if (response && response.status === 200) return 'success';
    return response.data;
  };

  forgotPassword = async (forgotPasswordModel: ForgotPasswordModel) => {
    const response = await this.axiosInstance.post(`Auth/ForgotPassword`, forgotPasswordModel, this.addTokenHeader());
    if (!response && !window.navigator.onLine) throw Error('You are offline. Please check your internet connection.');
    if (response && response.status === 200) return 'Password reset link sent.';
    throw Error('There was an internal error while sending password reset link. Please try again.');
  };

  resetPassword = async (resetPasswordModel: ResetPasswordModel) => {
    const response = await this.axiosInstance.post(`Auth/ResetPassword`, resetPasswordModel, this.addTokenHeader());
    if (!response && !window.navigator.onLine) throw Error('You are offline. Please check your internet connection.');
    if (response && response.status === 200) return 'Your password has been succesfully reset. You can now use it to Sign In.';
    throw Error('There was an internal error while resetting your password. Please try again.');
  };
}

export default AuthService;
