import { Redirect, Route, Switch } from 'react-router-dom';

import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { Homepage } from './pages/Homepage/Homepage';
import Logout from 'pages/Logout/Logout';
import ResetPassword from 'pages/ResetPassword/ResetPassword';

// In this project routes are controlled both in frontend and backend, so when updating DefaultRoutes, make sure to update the backend routes as well
export const DefaultRoutes = {
  SignIn: '/Auth/Index',
  SignUp: '/Auth/Register',
  ResetPassword: '/Auth/ResetPassword',
  Logout: '/Auth/SignOutCallback',
  Error: '/Home/ErrorCallback',
  About: '/Home/About',
  CookiePolicy: '/Home/CookiePolicy',
  PrivacyNotice: '/Home/PrivacyNotice',
  TermsAndConditions: '/Home/TermsAndConditions',
};

export const routes = (
  <Switch>
    <Route exact path={DefaultRoutes.SignIn}>
      <Homepage />
    </Route>
    <Route exact path={DefaultRoutes.SignUp}>
      <Homepage />
    </Route>
    <Route exact path={DefaultRoutes.About}>
      <Homepage />
    </Route>
    <Route exact path={DefaultRoutes.CookiePolicy}>
      <Homepage />
    </Route>
    <Route exact path={DefaultRoutes.PrivacyNotice}>
      <Homepage />
    </Route>
    <Route exact path={DefaultRoutes.TermsAndConditions}>
      <Homepage />
    </Route>
    <Route exact path={DefaultRoutes.ResetPassword}>
      <ResetPassword />
    </Route>
    <Route exact path={DefaultRoutes.Logout}>
      <Logout />
    </Route>
    <Route exact path={DefaultRoutes.Error}>
      <ErrorPage />
    </Route>
    <Redirect to={DefaultRoutes.SignIn} />
  </Switch>
);
