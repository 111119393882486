import { CardContainer } from 'components/CardContainer/CardContainer';
import { CardHeader } from 'components/CardHeader/CardHeader';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const TermsAndConditionsCard = observer(() => {
  const { t } = useTranslation();

  return (
    <CardContainer>
      <CardHeader header={t('terms.termsAndConditions')} />
      <h5>{t('terms.privacyPolicy')}</h5>
      <p>{t('terms.privacyPolicy_first_paragraph')}</p>
      <p>{t('terms.privacyPolicy_second_paragraph')}</p>

      <h5>{t('terms.outTechnology')}</h5>
      <p>{t('terms.outTechnology_first_paragraph')}</p>

      <h5>{t('terms.collectionOfData')}</h5>
      <p>{t('terms.collectionOfData_first_paragraph')}</p>
      <p>{t('terms.collectionOfData_second_paragraph')}</p>

      <h6>{t('terms.personalInformation')}</h6>
      <p>{t('terms.personalInformation_first_paragraph')}</p>

      <h6>{t('terms.trackManData')}</h6>
      <p>{t('terms.trackManData_first_paragraph')}</p>

      <h6>{t('terms.onlineUsageData')}</h6>
      <p>{t('terms.onlineUsageData_first_paragraph')}</p>
      <p>{t('terms.onlineUsageData_second_paragraph')}</p>

      <h5>{t('terms.dataUsage')}</h5>
      <p>{t('terms.dataUsage_first_paragraph')}</p>

      <h6>{t('terms.personalBenefits')}</h6>
      <p>{t('terms.personalBenefits_first_paragraph')}</p>

      <h6>{t('terms.statisticalPurposes')}</h6>
      <p>{t('terms.statisticalPurposes_first_paragraph')}</p>

      <h6>{t('terms.useInCompetitions')}</h6>
      <p>{t('terms.useInCompetitions_first_paragraph')}</p>

      <h6>{t('terms.forUseAsScouting')}</h6>
      <p>{t('terms.forUseAsScouting_first_paragraph')}</p>

      <h6>{t('terms.websiteActivities')}</h6>
      <p>{t('terms.websiteActivities_first_paragraph')}</p>
      <p>{t('terms.websiteActivities_second_paragraph')}</p>

      <h5>{t('terms.otherThirdPartyAccessToPersonalData')}</h5>
      <p>{t('terms.otherThirdPartyAccessToPersonalData_first_paragraph')}</p>
      <p>{t('terms.otherThirdPartyAccessToPersonalData_second_paragraph')}</p>
      <p>{t('terms.otherThirdPartyAccessToPersonalData_third_paragraph')}</p>
      <p>{t('terms.otherThirdPartyAccessToPersonalData_fourth_paragraph')}</p>

      <h5>{t('terms.storageOfPersonalData')}</h5>
      <p>{t('terms.storageOfPersonalData_first_paragraph')}</p>
      <p>{t('terms.storageOfPersonalData_second_paragraph')}</p>

      <h5>{t('terms.yourRights')}</h5>
      <p>{t('terms.yourRights_first_paragraph')}</p>

      <h5>{t('terms.promotionalEmails')}</h5>
      <p>{t('terms.promotionalEmails_first_paragraph')}</p>
      <p>{t('terms.promotionalEmails_second_paragraph')}</p>
      <p>{t('terms.promotionalEmails_third_paragraph')}</p>

      <h5>{t('terms.yourUseOfTheTrackmanWebsite')}</h5>
      <p>{t('terms.yourUseOfTheTrackmanWebsite_first_paragraph')}</p>
      <p>{t('terms.yourUseOfTheTrackmanWebsite_second_paragraph')}</p>
      <p>{t('terms.yourUseOfTheTrackmanWebsite_third_paragraph')}</p>
      <p>{t('terms.yourUseOfTheTrackmanWebsite_fourth_paragraph')}</p>

      <h5>{t('terms.otherImportantNotices')}</h5>

      <h6>{t('terms.changeOfControl')}</h6>
      <p>{t('terms.changePfControl_first_paragraph')}</p>

      <h6>{t('terms.disputeResolution')}</h6>
      <p>{t('terms.disputeResolution_first_paragraph')}</p>
      <p>{t('terms.disputeResolution_second_paragraph')}</p>

      <h6>{t('terms.reportingCopyright')}</h6>
      <p>{t('terms.reportingCopyright_first_paragraph')}</p>
      <p>{t('terms.reportingCopyright_second_paragraph')}</p>
      <p>{t('terms.reportingCopyright_third_paragraph')}</p>
      <p>{t('terms.reportingCopyright_fourth_paragraph')}</p>

      <h6>{t('terms.changesToOurPrivacyPolicy')}</h6>
      <p>{t('terms.changesToOurPrivacyPolicy_first_paragraph')}</p>
      <p>{t('terms.changesToOurPrivacyPolicy_second_paragraph')}</p>

      <h6>{t('terms.howToContactUs')}</h6>
      <p>{t('terms.howToContactUs_first_paragraph')}</p>
      <p>
        {t('terms.howToContactUsTrackman')}
        <br />
        {t('terms.howToContactUsStreet')}
        <br />
        {t('terms.howToContactUsCity')}
        <br />
        {t('terms.howToContactUsNumber')}
        <br />
        {t('terms.howToContactUsEmail')}
      </p>
    </CardContainer>
  );
});

export default TermsAndConditionsCard;
