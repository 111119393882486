import { AxiosInstance, AxiosResponse } from 'axios';

export class AxiosServiceConfig {
  static WaitingForToken: boolean = false;
  static setDefaultDecorators = (axiosInstance: AxiosInstance) => {
    const responseDefaultErrorHandler = (error: any) => {
      return Promise.resolve(error.response);
    };

    const responseDefaultDecorator = (response: AxiosResponse<any>) => {
      if (response.headers['content-type'] && response.headers['content-type'].indexOf('application/json') > -1) {
        response.data = {
          isError: false,
          data: response.data,
        };
      }
      return response;
    };

    // Add a request and response interceptor
    axiosInstance.interceptors.response.use(responseDefaultDecorator, responseDefaultErrorHandler);
  };
}
