import { Button } from '@trackman/web-shared-components';
import { CardContainer } from 'components/CardContainer/CardContainer';
import { CardHeader } from 'components/CardHeader/CardHeader';
import { DefaultRoutes } from 'Routes';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

const EmailVerificationCard = observer(() => {
  const as = useStores().authStore;
  const { t } = useTranslation();
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);

  return (
    <CardContainer>
      <CardHeader header={t('register.success')} />
      <p style={{ textAlign: 'center' }}>{t('register.success.hint')}</p>
      <p>{as.signUpModel.email}</p>
      <Button
        label={t('signIn')}
        onClick={() => {
          setLoading(true);
          window.location.assign(window.location.origin + DefaultRoutes.SignIn + history.location.search);
        }}
        intent={'primary'}
        isLoading={isLoading}
        disabled={isLoading}
        data-cy='verification-btn-goHome'
      />
    </CardContainer>
  );
});

export default EmailVerificationCard;
