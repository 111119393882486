import './ResetPassword.scss';

import { Button, Input } from '@trackman/web-shared-components';
import { hasLeadingOrTrailingSpaces, isCorrectPassword } from 'utilities/inputTests';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { CardContainer } from 'components/CardContainer/CardContainer';
import { CardHeader } from 'components/CardHeader/CardHeader';
import { DefaultRoutes } from 'Routes';
import { observer } from 'mobx-react-lite';
import useFocusOnCardLoad from 'hooks/useFocusOnCardLoad';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

const ResetPassword = observer(() => {
  const { search } = useLocation();
  const as = useStores().authStore;
  const { t } = useTranslation();
  const history = useHistory();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPasswrod, setConfirmPasswrod] = useState('');

  const [passInputLeft, setPassInputLeft] = useState(false);
  const [passConfirmInputLeft, setPassConfirmInputLeft] = useState(false);

  const arePasswordsMatching = useMemo(() => newPassword === confirmPasswrod, [newPassword, confirmPasswrod]);

  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(search);
    const email = query.get('email');
    const code = query.get('code');
    if (email) setEmail(email);
    if (code) setCode(code);
    query.delete('email');
    query.delete('code');
    history.replace({
      search: query.toString(),
    });
  }, [search, history]);

  const { refFocusOnCardLoad } = useFocusOnCardLoad<HTMLInputElement>();

  return (
    <div className='reset-password-container'>
      <CardContainer>
        <CardHeader header={t('reset.password.createNew')} />
        <Input
          label={t('password.new')}
          value={newPassword}
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setNewPassword(e.currentTarget.value);
          }}
          error={passInputLeft && !isCorrectPassword(newPassword)}
          hint={
            passInputLeft && !isCorrectPassword(newPassword)
              ? hasLeadingOrTrailingSpaces(newPassword)
                ? t('password.hint.space')
                : t('password.hint')
              : undefined
          }
          type='password'
          margin={'0 0 16px 0'}
          data-cy='reset-input-newPassword'
          onBlur={() => setPassInputLeft(true)}
          inputRef={refFocusOnCardLoad}
          autoComplete='new-password'
        />
        <Input
          label={t('password.new.confirm')}
          value={confirmPasswrod}
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setConfirmPasswrod(e.currentTarget.value);
          }}
          error={passConfirmInputLeft && !arePasswordsMatching}
          hint={passConfirmInputLeft && !arePasswordsMatching ? t('password.no.match') : undefined}
          type='password'
          margin={'0 0 16px 0'}
          data-cy='reset-input-confirmPasswrod'
          onBlur={() => setPassConfirmInputLeft(true)}
          autoComplete='new-password'
        />
        <Button
          label={t('password.reset')}
          onClick={async () => {
            await as.resetPassword({ email, new_password: newPassword, code });
            history.replace(DefaultRoutes.SignIn + search);
          }}
          intent={'primary'}
          disabled={!email || !arePasswordsMatching || as.isLoadingResetPassword || !isCorrectPassword(newPassword)}
          margin={'0 0 16px 0'}
          isLoading={as.isLoadingResetPassword}
          data-cy='reset-btn-reset'
        />
      </CardContainer>
    </div>
  );
});

export default ResetPassword;
