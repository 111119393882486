import { configure, makeAutoObservable } from 'mobx';

import { RefObject } from 'react';

configure({ enforceActions: 'never' });

export const cardSteps = [
  'signIn',
  'MFA',
  'forgotPassword',
  'signUp',
  'privacyNote',
  'emailVerification',
  'aboutTM',
  'cookiePolicy',
  'privacyNotice',
  'termsAndConditions',
] as const;
export type CardStep = (typeof cardSteps)[number];

export class CardStepStore {
  constructor() {
    makeAutoObservable(this);
  }

  cardStep: CardStep = 'signIn';
  appContainerRef: RefObject<HTMLDivElement> | null = null;

  setCardStep = (newCardStep: CardStep) => {
    this.cardStep = newCardStep;
    if (this.appContainerRef && this.appContainerRef.current)
      this.appContainerRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
}

export default CardStepStore;
