import { CardContainer } from 'components/CardContainer/CardContainer';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const AboutTMCard = observer(() => {
  const { t } = useTranslation();

  return (
    <CardContainer>
      <h5 className='about-title'>{t('about.trackmanUnleashedPotential')}</h5>
      <p>{t('about.trackmanUnleashedPotential_first_paragraph')}</p>
      <p>{t('about.trackmanUnleashedPotential_second_paragraph')}</p>
    </CardContainer>
  );
});

export default AboutTMCard;
