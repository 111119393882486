import { CardContainer } from 'components/CardContainer/CardContainer';
import { CardHeader } from 'components/CardHeader/CardHeader';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const CookiePolicyCard = observer(() => {
  const { t } = useTranslation();

  return (
    <CardContainer>
      <CardHeader header={t('cookie.cookiePolicy')} />
      <p>{t('cookie.cookiePolicy_first_paragraph')}</p>
      <p>{t('cookie.cookiePolicy_second_paragraph')}</p>
      <p>{t('cookie.cookiePolicy_third_paragraph')}</p>

      <h5>{t('cookie.howWeUseCookies')}</h5>
      <p>{t('cookie.howWeUseCookies_first_paragraph')}</p>
      <p dangerouslySetInnerHTML={{ __html: t('cookie.howWeUseCookies_second_paragraph') }}></p>
      <h5>{t('cookie.managingCookies')}</h5>
      <p>{t('cookie.managingCookies_first_paragraph')}</p>
      <p>{t('cookie.managingCookies_second_paragraph')}</p>

      <h5>{t('cookie.changesToThisCookiesPolicy')}</h5>
      <p>{t('cookie.changesToThisCookiesPolicy_first_paragraph')}</p>

      <h5>{t('cookie.cookiesContact')}</h5>
      <p>{t('cookie.cookiesContact_first_paragraph')}</p>
    </CardContainer>
  );
});

export default CookiePolicyCard;
