import { Button, Input } from '@trackman/web-shared-components';

import { CardContainer } from 'components/CardContainer/CardContainer';
import { CardHeader } from 'components/CardHeader/CardHeader';
import { isEmail } from 'utilities/inputTests';
import { observer } from 'mobx-react-lite';
import useFocusOnCardLoad from 'hooks/useFocusOnCardLoad';
import { useState } from 'react';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

const ForgotPasswordCard = observer(() => {
  const css = useStores().cardStepStore;
  const as = useStores().authStore;
  const { t } = useTranslation();

  const [email, setEmail] = useState('');

  const [emailInputLeft, setEmailInputLeft] = useState(false);

  const forgotPassword = () => (isEmail(email) ? as.forgotPassword(email) : null);

  const { refFocusOnCardLoad } = useFocusOnCardLoad<HTMLInputElement>({ cardStep: 'forgotPassword' });

  return (
    <CardContainer>
      <CardHeader header={t('password.forgot')} onGoBack={() => css.setCardStep('signIn')} />
      <div>
        <p>{t('password.reset.hint')}</p>
        <p>{t('password.reset.hint2')}</p>
      </div>
      <Input
        label={t('Email')}
        type='email'
        value={email}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(e.currentTarget.value);
        }}
        error={emailInputLeft && !isEmail(email)}
        hint={emailInputLeft && !isEmail(email) ? t('email.hint') : undefined}
        margin={'0 0 16px 0'}
        onKeyDown={(e) => (e.key === 'Enter' ? forgotPassword() : null)}
        data-cy='forgot-input-email'
        onBlur={() => setEmailInputLeft(true)}
        inputRef={refFocusOnCardLoad}
        autoComplete='username'
      />
      <Button
        label={t('password.reset')}
        onClick={forgotPassword}
        intent={'primary'}
        disabled={!isEmail(email) || as.isLoadingForgotPassword}
        isLoading={as.isLoadingForgotPassword}
        data-cy='forgot-btn-reset'
      />
    </CardContainer>
  );
});

export default ForgotPasswordCard;
