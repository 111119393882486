import './CardHeader.scss';

import { ReactComponent as BackIcon } from 'images/back-arrow.svg';

type Props = {
  header: string;
  onGoBack?: () => void;
};

export const CardHeader = ({ header, onGoBack }: Props) => {
  return (
    <div className={`home-card-header ${onGoBack ? 'go-back' : ''}`} onClick={onGoBack}>
      {onGoBack && <BackIcon />}
      {header}
    </div>
  );
};
