import './TabBar.scss';

import { DefaultRoutes } from 'Routes';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

const tabs = ['signIn', 'signUp'] as const;
type Tab = (typeof tabs)[number];

export const TabBar = observer(() => {
  const css = useStores().cardStepStore;
  const { t } = useTranslation();
  const history = useHistory();

  const selectedTab: Tab | undefined = useMemo(
    () =>
      css.cardStep === 'signIn' || css.cardStep === 'forgotPassword' || css.cardStep === 'MFA'
        ? 'signIn'
        : css.cardStep === 'emailVerification' || css.cardStep === 'privacyNote' || css.cardStep === 'signUp'
        ? 'signUp'
        : undefined,
    [css.cardStep]
  );

  return (
    <div className='tabbar-container'>
      {tabs.map((tab) => (
        <div
          key={tab}
          className={`tabbar-item ${selectedTab === tab ? 'user-is-here' : ''}`}
          onClick={() => {
            if (selectedTab === undefined) css.setCardStep(tab);
            history.replace((tab === 'signIn' ? DefaultRoutes.SignIn : DefaultRoutes.SignUp) + history.location.search);
          }}
          data-cy={`tabbar-item-${tab}`}
        >
          {t(tab)}
        </div>
      ))}
    </div>
  );
});
