import { Button, Input } from '@trackman/web-shared-components';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { CardContainer } from 'components/CardContainer/CardContainer';
import { isEmail } from 'utilities/inputTests';
import { observer } from 'mobx-react-lite';
import useFocusOnCardLoad from 'hooks/useFocusOnCardLoad';
import { useLocation } from 'react-router-dom';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

const SignInCard = observer(() => {
  const css = useStores().cardStepStore;
  const as = useStores().authStore;
  const { t } = useTranslation();
  const { search } = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [emailInputLeft, setEmailInputLeft] = useState(false);

  const returnUrl = useMemo(() => new URLSearchParams(search).get('ReturnUrl') ?? '/', [search]);

  const signIn = useCallback(
    () => (email && password ? as.signIn({ email: email, password: password }, returnUrl) : null),
    [email, password, as, returnUrl]
  );

  useEffect(() => {
    if (as.isMFAOn) {
      as.emailForMFA = email;
      css.setCardStep('MFA');
    }
  }, [as.isMFAOn, email, as, css]);

  const { refFocusOnCardLoad } = useFocusOnCardLoad<HTMLInputElement>({ cardStep: 'signIn' });

  return (
    <CardContainer>
      <Input
        label={t('Email')}
        type='email'
        value={email}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(e.currentTarget.value);
        }}
        margin={'0 0 16px 0'}
        onKeyDown={(e) => (e.key === 'Enter' ? signIn() : null)}
        error={emailInputLeft && !isEmail(email)}
        hint={emailInputLeft && !isEmail(email) ? t('email.hint') : undefined}
        data-cy='signIn-input-email'
        onBlur={() => setEmailInputLeft(true)}
        inputRef={refFocusOnCardLoad}
        autoComplete='username'
      />
      <Input
        label={t('Password')}
        value={password}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setPassword(e.currentTarget.value);
        }}
        type='password'
        margin={'0 0 16px 0'}
        onKeyDown={(e) => (e.key === 'Enter' ? signIn() : null)}
        data-cy='signIn-input-password'
        autoComplete='current-password'
      />
      <Button
        label={t('signIn')}
        onClick={signIn}
        intent={'primary'}
        disabled={!isEmail(email) || !password || as.isLoadingSignIn}
        data-cy='signIn-btn-signIn'
        isLoading={as.isLoadingSignIn}
      />
      <Button
        label={t('password.forgot')}
        onClick={() => css.setCardStep('forgotPassword')}
        intent={'tertiary'}
        data-cy='signIn-btn-forgotPassword'
      />
    </CardContainer>
  );
});

export default SignInCard;
