import ToastContent from '@trackman/web-shared-components/components/Toast/ToastContent';
import { ToastProps } from '@trackman/web-shared-components';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

export const Notifications = observer(() => {
  const notificationsStore = useStores().notificationStore;

  return (
    <div
      style={{
        top: '24px',
        right: '24px',
        zIndex: 2002,
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      {notificationsStore.toasts &&
        notificationsStore.toasts.map((toast: ToastProps, index: number) => {
          return (
            <ToastContent
              onClose={() => notificationsStore.handleOnClose(toast.id ? toast.id : '')}
              key={index}
              severity={toast.severity}
              header={toast.header}
              message={toast.message}
              margin='8px'
            />
          );
        })}
    </div>
  );
});
