import { Button } from '@trackman/web-shared-components';
import { CardContainer } from 'components/CardContainer/CardContainer';
import { CardHeader } from 'components/CardHeader/CardHeader';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const PrivacyNoteCard = observer(() => {
  const css = useStores().cardStepStore;
  const as = useStores().authStore;
  const { t } = useTranslation();
  const { search } = useLocation();

  const returnUrl = useMemo(() => new URLSearchParams(search).get('ReturnUrl') ?? '/', [search]);

  return (
    <CardContainer>
      <CardHeader header={t('privacy.notice')} />
      <div>
        <p>{t('privacy.notice.text')}</p>
        <p>{t('privacy.notice.text2')}</p>
      </div>
      <Button
        label={t('privacy.read')}
        onClick={async () => {
          const result = await as.signUp(returnUrl);
          if (result === 'success') css.setCardStep('emailVerification');
          if (result === 'error') css.setCardStep('signUp');
        }}
        intent={'primary'}
        isLoading={as.isLoadingSignUp}
        disabled={as.isLoadingSignUp}
        data-cy='privacy-btn-read'
        width={250}
      />
    </CardContainer>
  );
});

export default PrivacyNoteCard;
