import 'bootstrap/dist/css/bootstrap.css';

import * as RoutesModule from 'Routes';

import { createContext, useContext } from 'react';

import App from './App';
import { AuthService } from 'services/AuthService';
import { AuthStore } from 'stores/AuthStore';
import { AxiosInstance } from 'axios';
import { AxiosServiceConfig } from 'services/AxiosServiceConfig';
import { AxiosServiceFactory } from 'services/AxiosServiceFactory';
import { BrowserRouter } from 'react-router-dom';
import CardStepStore from 'stores/CardStepStore';
import { ConfigurationService } from 'services/ConfigurationService';
import { NotificationsStore } from 'stores/NotificationsStore';
import { createRoot } from 'react-dom/client';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsEn from './locales/en/translation-en.json';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

type Stores = {
  configurationService: ConfigurationService;
  apiServiceAxiosInstance: AxiosInstance;
  notificationStore: NotificationsStore;
  authStore: AuthStore;
  cardStepStore: CardStepStore;
};

const StoresContext = createContext<Stores | null>(null);

export const useStores = () => {
  const stores = useContext(StoresContext);
  if (!stores) {
    throw new Error('useStores must be used within a StoreProvider.');
  }
  return stores;
};

const langResources = {
  en: { translation: translationsEn },
};

const isLangAvailable = (lang: string) => {
  return Object.keys(langResources).includes(lang) ? lang : '';
};

async function RenderApp() {
  const configurationService = new ConfigurationService();
  const serviceFactory = new AxiosServiceFactory(configurationService);
  const apiServiceAxiosInstance = await serviceFactory.createInstance();
  const authService = new AuthService(apiServiceAxiosInstance);
  const notificationStore = new NotificationsStore();
  const authStore = new AuthStore(authService, notificationStore);
  const cardStepStore = new CardStepStore();

  const stores: Stores = {
    configurationService,
    apiServiceAxiosInstance,
    notificationStore,
    authStore,
    cardStepStore,
  };

  await i18n.use(initReactI18next).init({
    resources: langResources,
    lng: isLangAvailable(navigator.language.slice(0, 2)) || 'en',
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  });

  // Add a request and response interceptor
  AxiosServiceConfig.setDefaultDecorators(apiServiceAxiosInstance);

  root.render(
    <BrowserRouter>
      <StoresContext.Provider value={stores}>
        <App>{RoutesModule.routes}</App>
      </StoresContext.Provider>
    </BrowserRouter>
  );
}

RenderApp();
