import { configure, makeAutoObservable } from 'mobx';

import AuthService from 'services/AuthService';
import { NotificationsStore } from './NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export type LoginModel = {
  email: string;
  password: string;
};

export type LoginWith2faModel = {
  email: string;
  two_factor_code: string;
  remember_machine: boolean;
};

export type SignUpModel = {
  email: string;
  password: string;
  are_toc_accepted: boolean;
  are_marketing_updates_accepted: boolean;
};

export type ResetPasswordModel = {
  email: string;
  new_password: string;
  code: string;
};

export type ForgotPasswordModel = {
  email: string;
  sport_bg: string;
};

export type SignUpErrorCodes = 'toc_not_accepted' | 'duplicate_email';

export const sportBgs = ['baseball', 'footballus', 'soccer'];
export type SportBg = (typeof sportBgs)[number];

const errorCodesMapper: { [key in SignUpErrorCodes]: string } = {
  toc_not_accepted: 'Terms and Conditions not accepted.',
  duplicate_email: 'User with provided e-mail already exists.',
};

export class AuthStore {
  private authService: AuthService;
  private ns: NotificationsStore;

  constructor(authService: AuthService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.authService = authService;
    this.ns = notificationStore;
  }

  isLoadingSignIn: boolean = false;
  isLoadingSignUp: boolean = false;
  isLoadingForgotPassword: boolean = false;
  isLoadingResetPassword: boolean = false;
  isLoadingMFASubmit: boolean = false;

  emailForMFA: string = '';
  isMFAOn: boolean = false;

  signUpModel: SignUpModel = {
    email: '',
    password: '',
    are_toc_accepted: false,
    are_marketing_updates_accepted: false,
  };

  sportBg: SportBg = 'baseball';

  signIn = async (loginModel: LoginModel, returnUrl: string) => {
    try {
      this.isLoadingSignIn = true;
      const response = await this.authService.signIn(loginModel, returnUrl);
      if (response === 'MFA required') {
        this.isMFAOn = true;
        this.isLoadingSignIn = false;
      }
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
      this.isLoadingSignIn = false;
    }
  };

  submitMFA = async (loginWith2faModel: LoginWith2faModel, returnUrl: string) => {
    try {
      this.isLoadingMFASubmit = true;
      await this.authService.submitMFA(loginWith2faModel, returnUrl);
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
      this.isLoadingMFASubmit = false;
    }
  };

  signUp = async (returnUrl: string): Promise<'success' | 'error'> => {
    try {
      this.isLoadingSignUp = true;
      const response = await this.authService.signUp(this.signUpModel, returnUrl);
      if (response === 'success') return 'success';
      this.ns.addToast('error', errorCodesMapper[response], 'error');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoadingSignUp = false;
    return 'error';
  };

  forgotPassword = async (email: string) => {
    try {
      this.isLoadingForgotPassword = true;
      const reposnse = await this.authService.forgotPassword({ email, sport_bg: this.sportBg });
      this.ns.addToast('Success', reposnse, 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoadingForgotPassword = false;
  };

  resetPassword = async (resetPasswordModel: ResetPasswordModel) => {
    try {
      this.isLoadingResetPassword = true;
      const reposnse = await this.authService.resetPassword(resetPasswordModel);
      this.ns.addToast('Password reset', reposnse, 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoadingResetPassword = false;
  };
}

export default AuthStore;
