import './CardContainer.scss';

import React from 'react';

type Props = {
  children: React.ReactNode;
};

export const CardContainer = ({ children }: Props) => {
  return <div className='login-card'>{children}</div>;
};
