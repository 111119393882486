import shortid from 'shortid';
import { makeAutoObservable } from 'mobx';
import { ToastProps } from '@trackman/web-shared-components';

export class NotificationsStore {
  private maxToasts: number;
  private duration: number;
  toasts: ToastProps[] = [];

  constructor() {
    makeAutoObservable(this);
    this.duration = 5000;
    this.maxToasts = 3;
  }

  handleOnClose = async (id: string) => {
    this.toasts = this.toasts.filter((toast: ToastProps) => toast.id !== id);
  };

  addToast = async (header: string, message: string, severity: 'info' | 'success' | 'error') => {
    const id = shortid.generate();

    if (this.toasts.length >= this.maxToasts) this.toasts.shift();

    this.toasts.push({
      severity: severity,
      header: header,
      message: message,
      id: id,
    });

    new Promise((resolve) => {
      setTimeout(() => {
        resolve(id);
        this.toasts = this.toasts.filter((toast: ToastProps) => toast.id !== id);
      }, this.duration);
    });
  };
}
