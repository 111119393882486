import { useEffect, useMemo, useRef } from 'react';

import { CardStep } from 'stores/CardStepStore';
import { useStores } from 'index';

type Props = {
  cardStep?: CardStep;
};

const useFocusOnCardLoad = <T extends HTMLInputElement | HTMLButtonElement>(props?: Props) => {
  const refFocusOnCardLoad = useRef<T>(null);
  const css = useStores().cardStepStore;

  const cardStep = useMemo(() => props && props.cardStep, [props]);

  useEffect(() => {
    if (cardStep && css.cardStep !== cardStep) return;
    if (document.activeElement !== refFocusOnCardLoad.current) setTimeout(() => refFocusOnCardLoad.current?.focus(), 300);
  }, [css.cardStep, cardStep]);

  return { refFocusOnCardLoad };
};

export default useFocusOnCardLoad;
