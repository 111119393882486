import { Button, Checkbox, Input } from '@trackman/web-shared-components';
import { useCallback, useMemo, useRef, useState } from 'react';

import { CardContainer } from 'components/CardContainer/CardContainer';
import { CardHeader } from 'components/CardHeader/CardHeader';
import { observer } from 'mobx-react-lite';
import useFocusOnCardLoad from 'hooks/useFocusOnCardLoad';
import { useLocation } from 'react-router-dom';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

// Covering all edge cases makes this code a bit counterintuitive, but all of it is intentional.
// Please modify onChange and onKeyDown with caution and test all scenarios after any change.

const MFACard = observer(() => {
  const css = useStores().cardStepStore;
  const as = useStores().authStore;
  const { t } = useTranslation();
  const { search } = useLocation();

  const [MFAcode, setMFAcode] = useState<string[]>(['', '', '', '', '', '']);
  const [rememberMfa, setRememberMfa] = useState<boolean>(true);

  const allowedInputs = useMemo(() => ['', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'], []);

  const inputsArray = useMemo(() => Array.from(Array(6).keys()), []);

  const { refFocusOnCardLoad: ref1 } = useFocusOnCardLoad<HTMLInputElement>({ cardStep: 'MFA' });
  const ref2 = useRef<HTMLInputElement>(null);
  const ref3 = useRef<HTMLInputElement>(null);
  const ref4 = useRef<HTMLInputElement>(null);
  const ref5 = useRef<HTMLInputElement>(null);
  const ref6 = useRef<HTMLInputElement>(null);

  const refMapper = useMemo(() => [ref1, ref2, ref3, ref4, ref5, ref6], [ref1]);

  const returnUrl = useMemo(() => new URLSearchParams(search).get('ReturnUrl') ?? '/', [search]);

  const submit = useCallback(() => {
    as.submitMFA(
      {
        email: as.emailForMFA,
        two_factor_code: MFAcode.join(''),
        remember_machine: rememberMfa,
      },
      returnUrl
    );
  }, [as, MFAcode, rememberMfa, returnUrl]);

  return (
    <CardContainer>
      <CardHeader
        header={t('mfa.title')}
        onGoBack={() => {
          as.isMFAOn = false;
          css.setCardStep('signIn');
        }}
      />
      <p style={{ marginTop: '16px' }}>{t('mfa.info')}</p>
      <div className='mfa-input-container'>
        {inputsArray.map((i) => (
          <Input
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            key={`mfaInput-${i}`}
            inputRef={refMapper[i]}
            label=''
            value={MFAcode[i]}
            onFocus={() => refMapper[i].current?.select()} // when input is already filled, select its contents so new value can be instantly typed
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newValue = e.currentTarget.value;
              // if user pastes the code from clipboard, fill all inputs regardless into which one it is pasted
              if (newValue.length === 6 && /^\d+$/.test(newValue)) {
                setMFAcode(newValue.split(''));
                setTimeout(() => {
                  if (!MFAcode.includes('')) submit();
                }, 0); // timeout needed for the MFAcode to be set to new value
                return;
              }
              if (!allowedInputs.includes(newValue)) return; // exit if invalid character is entered
              setMFAcode((MFAcode) => {
                MFAcode[i] = newValue;
                return [...MFAcode]; // array copy needed to trigger rerender
              });
              setTimeout(() => {
                if (i < 5 && newValue !== '') refMapper[i + 1].current?.focus(); // move to the next field when value is entered
                if (i === 5 && !MFAcode.includes('')) submit(); // on the last input submit MFA code
              }, 0); // timeout needed for the MFAcode to be set to new value
            }}
            width={33}
            hiddenLabel
            onKeyDown={(e) => {
              if (e.key === 'ArrowLeft' && i > 0) {
                e.preventDefault();
                refMapper[i - 1].current?.focus();
              }
              if (e.key === 'ArrowRight' && i < 5) {
                e.preventDefault();
                refMapper[i + 1].current?.focus();
              }
              if (e.key === 'Enter' && !MFAcode.includes('')) {
                e.preventDefault();
                submit();
              }
              if (e.key === 'Backspace' && i > 0) {
                setTimeout(() => {
                  refMapper[i - 1].current?.focus(); // move to the previous field on Backspace, after onChange happened
                }, 0); // timeout needed for the MFAcode to be set to new value. Calling it in onKeyDown ensures it will be executed immediately after MFAcode update and user will see no delay
              }
              if (MFAcode[i] === e.key) {
                // if the user inputs new value that is equal to the previous one onChange event will not be triggered, hence it has to be handled here
                setTimeout(() => {
                  if (i < 5) refMapper[i + 1].current?.focus(); // move to the next field when value is entered
                  if (i === 5) submit(); // on the last input submit MFA code
                }, 0); // timeout needed for the MFAcode to be set to new value
              }
            }}
            data-cy={`mfa-input-${i}`}
            autoComplete={i === 0 ? 'one-time-code' : undefined}
          />
        ))}
      </div>
      <div className='checkbox-container'>
        <Checkbox
          label={t('mfa.remember')}
          checked={rememberMfa}
          reversed
          withoutBorder
          onChange={() => setRememberMfa((rememberMfa) => !rememberMfa)}
          data-cy='mfa-checkbox-rememberMfa'
        />
      </div>
      <Button
        label={t('signIn')}
        onClick={submit}
        intent={'primary'}
        disabled={MFAcode.includes('') || as.isLoadingMFASubmit}
        data-cy='mfa-btn-signIn'
        isLoading={as.isLoadingMFASubmit}
      />
    </CardContainer>
  );
});

export default MFACard;
