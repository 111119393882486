import * as axios from 'axios';
import { ConfigurationService } from './ConfigurationService';

export interface AxiosService {}

export class AxiosServiceFactory {
  private configService: ConfigurationService;

  // Instance
  private static apiService: axios.AxiosInstance;

  constructor(configService: ConfigurationService) {
    this.configService = configService;
  }

  async createInstance(): Promise<axios.AxiosInstance> {
    const baseUrl = this.configService.getWindowOrigin();
    return AxiosServiceFactory.apiService || (AxiosServiceFactory.apiService = this.createAxiosInstance(baseUrl, true));
  }

  private createAxiosInstance(baseUrl: string, withCredentials: boolean): axios.AxiosInstance {
    return axios.default.create({
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      baseURL: baseUrl,
      withCredentials: withCredentials,
    });
  }
}
