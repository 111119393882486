import { Button, Checkbox, Input } from '@trackman/web-shared-components';
import { hasLeadingOrTrailingSpaces, isCorrectPassword, isEmail } from 'utilities/inputTests';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { CardContainer } from 'components/CardContainer/CardContainer';
import { observer } from 'mobx-react-lite';
import useFocusOnCardLoad from 'hooks/useFocusOnCardLoad';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

const SignUpCard = observer(() => {
  const css = useStores().cardStepStore;
  const as = useStores().authStore;
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();

  const [confirmPasswrod, setConfirmPasswrod] = useState('');

  const [emailInputLeft, setEmailInputLeft] = useState(false);
  const [passInputLeft, setPassInputLeft] = useState(false);
  const [passConfirmInputLeft, setPassConfirmInputLeft] = useState(false);

  const arePasswordsMatching = useMemo(() => as.signUpModel.password === confirmPasswrod, [as.signUpModel.password, confirmPasswrod]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const email = query.get('prefilledEmail');
    if (email) as.signUpModel.email = email;
    query.delete('prefilledEmail');
    history.replace({
      search: query.toString(),
    });
  }, [search, history, as.signUpModel]);

  const { refFocusOnCardLoad } = useFocusOnCardLoad<HTMLInputElement>({ cardStep: 'signUp' });

  return (
    <CardContainer>
      <Input
        label={t('Email')}
        type='email'
        value={as.signUpModel.email}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          as.signUpModel.email = e.currentTarget.value;
        }}
        error={emailInputLeft && !isEmail(as.signUpModel.email)}
        hint={emailInputLeft && !isEmail(as.signUpModel.email) ? t('email.hint') : undefined}
        margin={'0 0 16px 0'}
        data-cy='signUp-input-email'
        onBlur={() => setEmailInputLeft(true)}
        inputRef={refFocusOnCardLoad}
        autoComplete='username'
      />
      <Input
        label={t('password.new')}
        value={as.signUpModel.password}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          as.signUpModel.password = e.currentTarget.value;
        }}
        error={passInputLeft && !isCorrectPassword(as.signUpModel.password)}
        hint={
          passInputLeft && !isCorrectPassword(as.signUpModel.password)
            ? hasLeadingOrTrailingSpaces(as.signUpModel.password)
              ? t('password.hint.space')
              : t('password.hint')
            : undefined
        }
        type='password'
        margin={'0 0 16px 0'}
        data-cy='signUp-input-password'
        onBlur={() => setPassInputLeft(true)}
        autoComplete='new-password'
      />
      <Input
        label={t('password.new.confirm')}
        value={confirmPasswrod}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setConfirmPasswrod(e.currentTarget.value);
        }}
        error={passConfirmInputLeft && !arePasswordsMatching}
        hint={passConfirmInputLeft && !arePasswordsMatching ? t('password.no.match') : undefined}
        type='password'
        data-cy='signUp-input-confirmPasswrod'
        onBlur={() => setPassConfirmInputLeft(true)}
        autoComplete='new-password'
      />
      <div className='checkbox-container'>
        <Checkbox
          label={t('signUp.accept.ToS')}
          checked={as.signUpModel.are_toc_accepted}
          reversed
          withoutBorder
          onChange={() => (as.signUpModel.are_toc_accepted = !as.signUpModel.are_toc_accepted)}
          data-cy='signUp-checkbox-isToS'
        />
        <Checkbox
          label={t('signUp.accept.Marketing')}
          checked={as.signUpModel.are_marketing_updates_accepted}
          reversed
          withoutBorder
          onChange={() => (as.signUpModel.are_marketing_updates_accepted = !as.signUpModel.are_marketing_updates_accepted)}
          data-cy='signUp-checkbox-isMarketing'
        />
      </div>
      <Button
        label={t('Next')}
        onClick={() => css.setCardStep('privacyNote')}
        intent={'primary'}
        disabled={
          !arePasswordsMatching ||
          !as.signUpModel.are_toc_accepted ||
          !isCorrectPassword(as.signUpModel.password) ||
          !isEmail(as.signUpModel.email)
        }
        data-cy='signUp-btn-next'
      />
    </CardContainer>
  );
});

export default SignUpCard;
