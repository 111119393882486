import { CardContainer } from 'components/CardContainer/CardContainer';
import { CardHeader } from 'components/CardHeader/CardHeader';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const PrivacyNoticeCard = observer(() => {
  const { t } = useTranslation();

  return (
    <CardContainer>
      <CardHeader header={t('privacy.privacyNotice')} />
      <p>{t('privacy.privacyNotice_first_paragraph')}</p>
      <p>{t('privacy.privacyNotice_second_paragraph')}</p>

      <h5>{t('privacy.privacyContact')}</h5>
      <p>{t('privacy.privacyContact_first_paragraph')}</p>
      <p>{t('privacy.privacyContact_second_paragraph')}</p>

      <h5>{t('privacy.whereDataMayBeCollected')}</h5>
      <p>{t('privacy.whereDataMayBeCollected_first_paragraph')}</p>

      <h6>{t('privacy.websiteBrowsing')}</h6>
      <p>{t('privacy.websiteBrowsing_first_paragraph')}</p>

      <h6>{t('privacy.enquiriesThroughTheTrackManSalesForce')}</h6>
      <p>{t('privacy.enquiriesThroughTheTrackManSalesForce_first_paragraph')}</p>

      <h6>{t('privacy.purchaseOfTrackManProduct')}</h6>
      <p>{t('privacy.purchaseOfTrackManProduct_first_paragraph')}</p>

      <h6>{t('privacy.openingOfTrackManAccount')}</h6>
      <p>{t('privacy.openingOfTrackManAccount_first_paragraph')}</p>

      <h6>{t('privacy.useOfProductsAndServices')}</h6>
      <p>{t('privacy.useOfProductsAndServices_first_paragraph')}</p>
      <p
        dangerouslySetInnerHTML={{
          __html: t('privacy.useOfProductsAndServices_second_paragraph', { interpolation: { escapeValue: false } }),
        }}
      ></p>
      <p
        dangerouslySetInnerHTML={{
          __html: t('privacy.useOfProductsAndServices_third_paragraph', { interpolation: { escapeValue: false } }),
        }}
      ></p>

      <h6>{t('privacy.collectionOfFunctionalData')}</h6>
      <p>{t('privacy.collectionOfFunctionalData_first_paragraph')}</p>

      <h5>{t('privacy.purposeOfProcessingPersonalInformation')}</h5>

      <ul>
        <li>{t('privacy.purposeOfProcessingPersonalInformation_list_1')}</li>
        <li>{t('privacy.purposeOfProcessingPersonalInformation_list_2')}</li>
        <li>{t('privacy.purposeOfProcessingPersonalInformation_list_3')}</li>
        <li>{t('privacy.purposeOfProcessingPersonalInformation_list_4')}</li>
        <li>{t('privacy.purposeOfProcessingPersonalInformation_list_5')}</li>
        <li>{t('privacy.purposeOfProcessingPersonalInformation_list_6')}</li>
        <li>{t('privacy.purposeOfProcessingPersonalInformation_list_7')}</li>
        <li>{t('privacy.purposeOfProcessingPersonalInformation_list_8')}</li>
        <li>{t('privacy.purposeOfProcessingPersonalInformation_list_9')}</li>
        <li>{t('privacy.purposeOfProcessingPersonalInformation_list_10')}</li>
        <li>{t('privacy.purposeOfProcessingPersonalInformation_list_11')}</li>
      </ul>

      <p>{t('privacy.purposeOfProcessingPersonalInformation_first_paragraph')}</p>
      <p>{t('privacy.purposeOfProcessingPersonalInformation_second_paragraph')}</p>

      <h5>{t('privacy.informationSharing')}</h5>
      <p>{t('privacy.informationSharing_first_paragraph')}</p>
      <p>{t('privacy.informationSharing_second_paragraph')}</p>

      <h5>{t('privacy.detailsOfTransfersToThirdCountryAndSafeguards')}</h5>
      <p>{t('privacy.detailsOfTransfersToThirdCountryAndSafeguards_first_paragraph')}</p>

      <h5>{t('privacy.retentionPeriods')}</h5>
      <p>{t('privacy.retentionPeriods_first_paragraph')}</p>
      <p>{t('privacy.retentionPeriods_second_paragraph')}</p>

      <h5>{t('privacy.useOfCookies')}</h5>
      <p>{t('privacy.useOfCookies_first_paragraph')}</p>

      <h5>{t('privacy.yourRights_privacy')}</h5>
      <p>{t('privacy.yourRights_privacy_first_paragraph')}</p>

      <ul>
        <li>{t('privacy.yourRights_privacy_list_1')}</li>
        <li>{t('privacy.yourRights_privacy_list_2')}</li>
        <li>{t('privacy.yourRights_privacy_list_3')}</li>
        <li>{t('privacy.yourRights_privacy_list_4')}</li>
        <li>{t('privacy.yourRights_privacy_list_5')}</li>
        <li>{t('privacy.yourRights_privacy_list_6')}</li>
        <li>{t('privacy.yourRights_privacy_list_7')}</li>
        <li>{t('privacy.yourRights_privacy_list_8')}</li>
      </ul>

      <h5>{t('privacy.questionsAndComplaints')}</h5>
      <p>{t('privacy.questionsAndComplaints_first_paragraph')}</p>

      <h6>{t('privacy.ifStillDissatisfied')}</h6>
      <p>
        {t('privacy.ifStillDissatisfied_contact_1')}
        <br />
        {t('privacy.ifStillDissatisfied_contact_2')}
        <br />
        {t('privacy.ifStillDissatisfied_contact_3')}
        <br />
        {t('privacy.ifStillDissatisfied_contact_4')}
        <br />
        {t('privacy.ifStillDissatisfied_contact_5')}
        <br />
        {t('privacy.ifStillDissatisfied_contact_6')}
      </p>

      <h5>{t('privacy.changes_privacy')}</h5>
      <p>{t('privacy.changes_privacy_first_paragraph')}</p>
    </CardContainer>
  );
});

export default PrivacyNoticeCard;
