import './Logout.scss';

import { useEffect, useMemo, useState } from 'react';

import { DefaultRoutes } from 'Routes';
import { Loader } from '@trackman/web-shared-components';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Logout = () => {
  const { search } = useLocation();
  const { t } = useTranslation();

  const signOutUrls = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.getAll('urls');
  }, [search]);

  const [loadedIframeUrls, setLoadedIframeUrls] = useState<string[]>([]);

  useEffect(() => {
    if (signOutUrls.length === loadedIframeUrls.length) window.location.assign(window.location.origin + DefaultRoutes.SignIn);
  }, [signOutUrls, loadedIframeUrls]);

  return (
    <div className='logout-container'>
      <Loader size='large' />
      <h3>{t('signOut.title')}</h3>
      <p>{t('signOut.subtitle')}</p>
      {signOutUrls.map((signOutUrl, i) => (
        <iframe
          title={`signOut-iframe-${signOutUrl}-${i}`}
          key={`${signOutUrl}-${i}`}
          src={decodeURIComponent(signOutUrl)}
          style={{ display: 'none' }}
          onLoad={() => setLoadedIframeUrls((loadedIframeUrls) => [...loadedIframeUrls, signOutUrl])}
        />
      ))}
    </div>
  );
};

export default Logout;
