export const isEmail = (email: string) => {
  // Input type="email" automatically trims leading and trailing spaces on submit
  return email !== '' && email.includes('@');
};

export const hasLeadingOrTrailingSpaces = (text: string) => {
  // IMPORTANT – Since Safari does not support lookbehind assertions, we need to use JS
  return text.length === 0 ? false : text[0] === ' ' || text[text.length - 1] === ' ';
};

export const isCorrectPassword = (password: string) => {
  const regexAll = /^(?=.*\d).{10,256}$/g; // 10-256 characters, at least one number

  // Leading and trailing spaces are not allowed to prevent user errors when copy-pasting passwords
  return !hasLeadingOrTrailingSpaces(password) && regexAll.test(password);
};
