import './ErrorPage.scss';

import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const { search } = useLocation();
  const { t } = useTranslation();

  const requestId = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('requestId');
  }, [search]);

  return (
    <div className='error-page-container'>
      <p>
        <strong>{t('error.error')}</strong> {t('error.description')}
      </p>
      {requestId && (
        <p>
          <strong>{t('error.requestId')}</strong> {requestId}
        </p>
      )}
    </div>
  );
};

export default ErrorPage;
